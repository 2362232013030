<div fxLayout="row wrap" fxLayoutAlign="space-between center" class="header">
    <h1 class="title__pages">Bienvenido</h1>
    <span></span>
    <p> <strong> {{ fecha | date:'fullDate'}}</strong></p>
</div>

<ng-container *ngIf="user.isCliente()">    
    <h2 class="cliente__title">NUESTROS PROYECTOS</h2>
    <div fxLayout='row wrap' fxLayoutAlign='space-around none'>            
        <mat-card fxFlex="32" fxFlex.lt-sm="90" >                
            <img mat-card-image class="image__size" src="/assets/images/proyecto4.webp" alt="Proyecto 1">
            <mat-card-content>
                <mat-card-title>Proyecto Nuevo Horizonte</mat-card-title>
                <p><em> Proyecto de vivienda</em> </p>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> 
            </mat-card-content>                
        </mat-card>  
        
        <mat-card fxFlex="32" fxFlex.lt-sm="90" >                
            <img mat-card-image class="image__size" src="/assets/images/proyecto2.webp" alt="Proyecto 3">
            <mat-card-content>
                <mat-card-title>Urb. Nueva Granada</mat-card-title>
                <p><em>Vivienda Unifamiliar</em> </p>
                <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing </p> 
            </mat-card-content>                
        </mat-card>
        
        <mat-card fxFlex="32" fxFlex.lt-sm="90" >                
            <img mat-card-image class="image__size" src="/assets/images/proyecto3.webp" alt="Proyecto 4">
            <mat-card-content>
                <mat-card-title>Edificio Román</mat-card-title>
                <p><em>Proyecto Workstation</em> </p>
                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia</p> 
            </mat-card-content>                
        </mat-card>  
    </div>
</ng-container>

<ng-container *ngIf="user.isAdministrador()">   
    <h2 class="subtitle">Datos Estadísticos del sitio</h2> 
    <div fxLayout="row wrap" fxLayoutAlign='space-around center'>
        <highcharts-chart fxFlex='100%'  
          [Highcharts]="Highcharts" 
          [options]="chartOptions"
          style="width: 100%; height: 400px; display: contents;"></highcharts-chart>          
      </div>
</ng-container>
<br>
<ng-container *ngIf="user.isPlanificador()">
    <div fxLayout="row wrap" fxLayoutAlign='space-around center'>
        <div fxFlex="45" fxFlex.lt-sm="90" fxLayout='column' fxLayoutAlign='center center'>
            <h2 class="subtitle">Proyectos Recientes</h2>
            <p *ngFor="let pro of lastProjects">
                Última vez: {{pro.updated_at | date:"d/MM/YY hh:mm:ss"}} - <a routerLink='/proyectos/proyecto-estudios/{{pro.id}}'>  {{pro.titulo}} </a>                
            </p>                 
            <div *ngIf="lastProjects">
                <p *ngIf="lastProjects.length === 0"> <em> No hay elementos agregados</em></p>       
            </div>
        </div>
        <div fxFlex="45" fxFlex.lt-sm="90" fxLayout='column' fxLayoutAlign='center center'>
            <h2 class="subtitle">Estudios Recientes</h2>            
                <p *ngFor="let pro of lastEstudios">
                    Última vez: {{pro.updated_at | date:"d/MM/YY hh:mm:ss"}} - <a routerLink='/proyectos/prefactibilidad-update/{{pro.proyecto_id}}/{{pro.id}}'> Inversión total ${{pro.inversionTotal | number:'1.2-2'}} </a>                
                </p>            
                <div *ngIf="lastEstudios">
                    <p *ngIf="lastEstudios.length === 0"> <em> No hay elementos agregados</em></p>
            </div>               
        </div>
    </div>
</ng-container>
<br>
<div fxLayout='column' fxLayoutAlign='center center' class='div-title'>
    <p class="title animate__animated animate__backInUp title_wetch" > [ Wetch ] </p>
    <small> <em> La solución arquitectónica eficaz para usted</em></small>
    <br>
</div>




import { Component, OnInit } from '@angular/core';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { AppState } from './app.reducer';
import { cookieAuthentication } from './auth/actions';
import { MessageService } from './utilitarios/messages/services/message.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  title = 'Wetch - Soluciones arquitectónicas';
  
  subtitle = 'wetch-app';  
  cookie=false;
  cookieValue='';

  constructor(
    private store:Store<AppState>,
    private cookieService: CookieService,
    private messageService:MessageService,
    private titleService: Title, 
    private metaService: Meta
  ){
    this.store.select('authApp').subscribe((response)=>{
      if(response.cookieAuthenticate){        
        this.cookie = true;
      }
    })
    this.cookieValue = this.cookieService.get('XSRF-TOKEN');    
    
  }
  
  ngOnInit(): void {
        
    if(!this.cookie || !this.cookieValue){ 
      this.store.dispatch(cookieAuthentication())
    }

    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'keywords', content: 'Wetch, Arquitectura, Prefactibilidad'},
      {name: 'description', content: 'Aplicación para el cálculo de prefactibilidad'},
      {name: 'robots', content: 'index, follow'}
    ]);
  }

  
}




<div class="title-page title-page__page" fxLayout='row grap' fxLayoutAlign="center center">
    <h2>NUESTRA EMPRESA</h2>
</div>

<div class="organizacion" fxLayout="row wrap" fxLayoutAlign="space-around none" >
    <mat-card fxFlex="45" fxFlex.lt-sm="80" class="organizacion__card mat-elevation-z24" >
        <mat-card-header class="organizacion__header">
            <mat-card-title class="organizacion__title"> MISIÓN </mat-card-title>            
        </mat-card-header>
        <mat-card-content class="organizacion__content">
            <p>Somos un grupo experto en diseño arquitectónico, para generar soluciones
                 creativas, auténticas e innovadoras para la región sur del Ecuador. Contamos 
                 con más de 15 años de experiencia en proyectos tan diversos que nos han
                  permitido estudiar sus soluciones y aprender de ellos.</p>
        </mat-card-content>
    </mat-card>
    
    <mat-card fxFlex="45" fxFlex.lt-sm="80" class="organizacion__card mat-elevation-z24">
        <mat-card-header class="organizacion__header">
            <mat-card-title class="organizacion__title">  VISIÓN </mat-card-title>            
        </mat-card-header>
        <mat-card-content class="organizacion__content">
            <p>
                Ser una empresa líder en la construcción, 
                diseño y proyectos de espacios arquitectónicos para el austro del Ecuador,
                integrada por un equipo de personas comprometidas, apasionadas 
                y sensibles a las necesidades de nuestros clientes.
            </p>
        </mat-card-content>
    </mat-card>
</div>

<div class="organizacion" fxLayout="row wrap" fxLayoutAlign="space-around none" >
    <div fxFlex="30" fxFlex.lt-md="80">
        <h3 class="organizacion__subtitle"> <strong> VALORES </strong></h3>
        <div>
            <h4>SOLIDARIDAD</h4>
            <p  class="justify">Somos un grupo personas honorables fundamentadas en la verdad y
                rectitud en el manejo de los recursos económicos.</p>            
        </div>

        <div>
            <h4>CONFIANZA</h4>
            <p class="justify">Aseguramos sus inversiones con actos que van de la mano con los 
                intereses de nuestros clientes e instituciones con quien trabajamos.
            </p>            
        </div>

        <div>
            <h4>CALIDAD</h4>
            <p class="justify">Comprometidos con aportar diseños de primera calidad buscando un valor 
                agregado para sus espacios e inmuebles.
            </p>            
        </div>

        <div>
            <h4>EFICIENCIA</h4>
            <p class="justify">Fuertemente inspirados con la acción de producir para respetar los compromisos 
                hechos con los clientes.
            </p>            
        </div>
        <div>
            <h4>AMABILIDAD</h4>
            <p class="justify">Empresa con el deber de comprender y tener clientes satisfechos.
            </p>            
        </div>
    </div>
    <div fxFlex="60" fxFlex.lt-sm="80">
        <h3 class="organizacion__subtitle"> <strong> PROFESIONALES</strong></h3>
        <div fxLayout='row wrap' fxLayoutAlign='space-around none'>            
            <mat-card fxFlex="35" fxFlex.lt-sm="80" >                
                <img mat-card-image src="/assets/images/wilson.webp" alt="Wilson Tapia">
                <mat-card-content>
                    <mat-card-title>Mgs. Wilson Tapia</mat-card-title>
                    <p><em> Gerente general</em> </p>
                    <p>Arquitecto. 20 años de experiencia en planificación, 
                        diseño y construcción. <br> Master en gestión de proyectos
                        por la Universidad de Cuenca.</p> 
                </mat-card-content>                
            </mat-card>  
            
            <mat-card fxFlex="35" fxFlex.lt-sm="80" >                
                <img mat-card-image src="/assets/images/paulo.webp" alt="Photo of a Shiba Inu">
                <mat-card-content>
                    <mat-card-title>Arq. Paulo Román</mat-card-title>
                    <p><em>Planificador</em> </p>
                    <p>Arquitecto e ingeniero civil, recibió su formación académica en la Universidad Técnica Particular de Loja. Ecuador</p> 
                </mat-card-content>                
            </mat-card>  
        </div>

    </div>
</div>

<div fxLayout='column' fxLayoutAlign='center center' class='div-title'>
    <p class="title animate__animated animate__backInUp title_wetch" > [ Wetch ] </p>
    <small> <em> La solución arquitectónica eficaz para usted</em></small>
    <br>
</div>
